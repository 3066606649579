import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ArticleHeader from "../components/ArticleHeader"
import parseContent from "../utils/parse-content"

const NotFoundPage = ({
  data,
  location,
}) => {

  const siteTitle = data.site.siteMetadata.title
  const localeSettings = data.assetsYaml

  return (
    <Layout location={location} title={siteTitle} is404Page settings={localeSettings}>
      <SEO title={data.post?.title} seo={data.post?.seo} />
      {/* <ArticleHeader
        backgroundColor="#350C13"
        overlay
        overline={<p>Oh No!</p>}
        headline={<h1>Story not found</h1>}
        subheadline={
          <p>We have lots of other interesting stories, though&hellip;</p>
        }
      />
      <p>
        Donec sed odio dui. Donec id elit non mi porta gravida at eget metus.
      </p> */}
      <article
        className="blog-post static-page"
        itemScope
        itemType="http://schema.org/Article"
      >
        {!!data.post?.content && (
          <section itemProp="articleBody">{parseContent(data.post?.content)}</section>
        )}
      </article>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query My404Query(
    $locale: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    post: wpPage(slug: { eq: "404" }, language: { locale: { eq: $locale } }) {
      id
      uri
      slug
      guid
      title
      content
      seo {
        metaDesc
        metaKeywords
        opengraphDescription
        opengraphImage {
          uri
          localFile {
            publicURL
          }
        }
        opengraphSiteName
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterImage {
          uri
          localFile {
            publicURL
          }
        }
        twitterTitle
      }
    }
    assetsYaml(locale: { eq: $locale }) {
      locale
      languagesMenu {
        text
        href
      }
      logo {
        title
        url
      }
      mainMenu {
        text
        href
      }
      mainMenuButtons {
        text
        href
      }
      footerTagLine
      socialMenu {
        text
        href
      }
      footerMenu {
        text
        href
      }
    }
  }
`